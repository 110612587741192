

.ant-tabs{
  width: 99% !important;
}

.ant-tabs-nav .ant-tabs-nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100% !important;
}


.ant-tabs-tab-btn{color: #f1f5f9a6;
  text-shadow: 0 0 0.25px currentcolor;
  font-weight: 600;}

  .ant-tabs .ant-tabs-tab {
    padding: 12px 9% !important;
  }
.custm .ant-tabs .ant-tabs-tab{
    padding: 12px 22% 12px 16% !important;
}


  .ant-tabs-tab:hover{background: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    border-radius: 8px;
    color: #f1f5f9a6;
  }

    .ant-tabs-tab-active{
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)) !important;
      border-radius: 8px !important;
      color: #f1f5f9a6;}

       .ant-tabs-tab-btn{

        border-radius: 8px !important;
        color: #f1f5f9a6 !important;}
.ant-card-body{

  padding: 0px !important;
  padding-left: 22px !important;
  padding-top: 5px;

}
.ant-card-head{
  min-height: 40px !important;

}
.ant-card-head-title{
color: rgb(223, 223, 223);
}
.ant-card-body p{
  color: rgb(223, 223, 223);
}
.ant-card-bordered{
  border: none;
}
.ant-card-body h7{
  color: rgb(223, 223, 223);
  font-weight: bold;
  margin-top: 9px;
}

.ant-card-body h4{
  color: rgb(223, 223, 223);

  margin-top: 9px;
}

.parent-div{
  position: relative;
}
.child-div{
  background: "linear-gradient(135deg, #3399ff 0%, #66ccff 100%)";
  position: absolute;
  top: -15%;

  width: 19%;
  height: 53%;
  border-radius: 5%;
  display: flex
}
.custm-card{
 background:  linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))!important
}
.ant-card-body hr{
  margin: 2% 5% 4% 2%
}
.parent-div2{
  position: relative;
}
.child-div2{

  position: absolute;
  top: -15%;

  width: 19%;
  height: 53%;
  border-radius: 5%;
  display: flex
}
.child-div3{
  background: linear-gradient(135deg, rgb(247, 107, 28) 0%, rgb(249, 177, 21) 100%);

  position: absolute;
  top: -15%;

  width: 25%;
  height: 55%;
  border-radius: 5%;
  display: flex
}
.custm-content{
  margin: 5% 6% 0 35%
}
.bottom-line-text{
  color: rgb(223, 223, 223); margin: 0 0 3%
}
.cusotm-ixon{
  margin:18% 24% 0 19%;
  font-size: xxx-large;
  color: whitesmoke;
}


.ant-table-cell-row-hover{
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))!important;
}
.ant-tabs-tabpane{
  width: 100% !important;
}


.cstm-btns{
  margin: 10% 8% 0% 9%;
}


 .ant-table-wrapper .ant-table{
  font-size: 13px !important;
}


.ant-card{
  line-height: 0.571429 !important;
  font-size: 15px !important;
    font-weight: 600 !important
}
.ant-card{
  height: 9rem;
}

.custm-table .ant-table-cell{
  padding: 16px 4px !important;
  width: 35% !important;
}

.card-padding{
  padding: 7px 6px 5px 6px;
}
.custpichart.recharts-wrapper{
  margin-left: -12%  !important;
}
.ant-pagination .ant-pagination-item-active{
  border-color: #575a5d  !important;
}


.table-header{border-radius:0;display:flex;left:4%;position:absolute;top:-3%;width:74%}.ant-table-wrapper{
  // margin-top:6%
}.table-header h4{color:#dfdfdf;margin:2% 0 2% 4%}




.anticon{
  color: #ffffff;
}



.ant-pagination-item{
  a{
    color: whitesmoke !important;
  }
}

.ant-pagination-item{
  background: transparent !important;
}


.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  background:#575a5d  !important ;
}

.ant-pagination-item-ellipsis{
  color: whitesmoke !important;
}


.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{
  color: whitesmoke !important;
}
.ant-select-selection-item{
  color: whitesmoke !important;
}


