
a:focus, a:active {
    text-decoration: none;
    outline: none;
    transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -webkit-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s; }

  input, select, textarea {
    outline: none;
    appearance: unset !important;
    -moz-appearance: unset !important;
    -webkit-appearance: unset !important;
    -o-appearance: unset !important;
    -ms-appearance: unset !important; }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    appearance: none !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    margin: 0; }

  input:focus, select:focus, textarea:focus {
    outline: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    -ms-box-shadow: none !important; }

  input[type=checkbox] {
    appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    -webkit-appearance: checkbox !important;
    -o-appearance: checkbox !important;
    -ms-appearance: checkbox !important; }

  input[type=radio] {
    appearance: radio !important;
    -moz-appearance: radio !important;
    -webkit-appearance: radio !important;
    -o-appearance: radio !important;
    -ms-appearance: radio !important; }

  img {
    max-width: 100%;
    height: auto; }

  figure {
    margin: 0; }



  h2 {
    line-height: 1.66;
    margin: 0;
    padding: 0;
    font-weight: 900;
    color: whitesmoke;
    font-family: math;
    font-size: 24px;

    text-align: center;
    // margin-bottom: 40px;
   }

  .clear {
    clear: both; }

  section {
    font-size: 14px;
    line-height: 1.8;
    color: #222;
    font-weight: 400;
    font-family: math;
    // background-image: url("../../../public/images/signup-bg.jpg");
    background: rgba(42,51,60,255);
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-position: center center;
    padding: 20px 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }

  .display-flex {
    justify-content: space-between;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center; }

  .display-flex-center {
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    -o-align-items: center;
    -ms-align-items: center; }

  .position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }

  .signup-content {
    background: rgb(50 60 72 / 88%);
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    padding: 50px 85px; }

  .form-group {
    overflow: hidden;
    margin-bottom: 20px; }

  .form-input {
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    padding: 17px 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #222; }
    .form-input::-webkit-input-placeholder {
      color: #999; }
    .form-input::-moz-placeholder {
      color: #999; }
    .form-input:-ms-input-placeholder {
      color: #999; }
    .form-input:-moz-placeholder {
      color: #999; }
    .form-input::-webkit-input-placeholder {
      font-weight: 500; }
    .form-input::-moz-placeholder {
      font-weight: 500; }
    .form-input:-ms-input-placeholder {
      font-weight: 500; }
    .form-input:-moz-placeholder {
      font-weight: 500; }
    .form-input:focus {
      border: 1px solid transparent;
      -webkit-border-image-source: -webkit-linear-gradient(to right, #9face6, #74ebd5);
      -moz-border-image-source: -moz-linear-gradient(to right, #9face6, #74ebd5);
      -o-border-image-source: -o-linear-gradient(to right, #9face6, #74ebd5);
      border-image-source: linear-gradient(to right, #9face6, #74ebd5);
      -webkit-border-image-slice: 1;
      border-image-slice: 1;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      -o-border-radius: 5px;
      -ms-border-radius: 5px;
      background-origin: border-box;
      background-clip: content-box, border-box; }
      .form-input:focus::-webkit-input-placeholder {
        color: #222; }
      .form-input:focus::-moz-placeholder {
        color: #222; }
      .form-input:focus:-ms-input-placeholder {
        color: #222; }
      .form-input:focus:-moz-placeholder {
        color: #222; }

  .form-submit {
    width: 100%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -o-border-radius: 5px;
    -ms-border-radius: 5px;
    padding: 17px 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    border: none;
    background: rgba(5,162,179,255);
  }

  input[type=checkbox]:not(old) {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    display: none; }

  input[type=checkbox]:not(old) + label {
    display: inline-block;
    margin-top: 7px;
    margin-bottom: 25px; }

  input[type=checkbox]:not(old) + label > span {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-bottom: 3px;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    -ms-border-radius: 2px;
    background: white;
    background-image: -moz-linear-gradient(white, white);
    background-image: -ms-linear-gradient(white, white);
    background-image: -o-linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: linear-gradient(white, white);
    vertical-align: bottom; }

  input[type=checkbox]:not(old):checked + label > span {
    background-image: -moz-linear-gradient(white, white);
    background-image: -ms-linear-gradient(white, white);
    background-image: -o-linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: linear-gradient(white, white); }

  input[type=checkbox]:not(old):checked + label > span:before {
    content: '\f26b';
    display: block;
    color: #222;
    font-size: 11px;
    line-height: 1.2;
    text-align: center;
    font-family: math;
    font-weight: bold; }

  .label-agree-term {
    font-size: 12px;
    font-weight: 600;
    color: #555; }

  .term-service {
    color: #555; }

  .loginhere {
    color: whitesmoke;
    font-weight: 500;
    text-align: center;
    margin-top: 38px;
    margin-bottom: 5px; }

  .field-icon {
    float: right;
    margin-right: 17px;
    margin-top: -32px;
    position: relative;
    z-index: 2;
    color: #555; }

  @media screen and (max-width: 768px) {
    .container {
      width: calc(100% - 40px);
      max-width: 100%; } }
  @media screen and (max-width: 480px) {
    .signup-content {
      padding: 50px 25px; } }

  /*# sourceMappingURL=style.css.map */


  .forgot-password {

    justify-content: center; /* Align to the right */
    margin-top: 8px; /* Adjust margin as needed */
    margin-bottom: 0px; /* Adjust margin as needed */
  }

  .forgot-password a {
    text-decoration: none; /* Remove underline */
    color: whitesmoke; /* Change color to your preferred color */
    font-size: 14px; /* Adjust font size if needed */
  }
  input::-ms-reveal,
  input::-ms-clear {
      display: none;
  }


  .signup {
    background-image: url('../../assests/N-Blog-Dynamic-Fabric-Architecture-and-Open-System-Visibility\ \(1\).png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding: 7rem;
    width: 100%;
  }

  .container-size, .input-group1 {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(2 2 2 / 36%); /* lightgrey with opacity */
    z-index: 1;
  }
  .error-message{
    color: whitesmoke;
    i{
      color: whitesmoke;
    }
  }
  .fa-eye{
    color: whitesmoke;
  }
  .fa-eye-slash{
    color: whitesmoke;
  }