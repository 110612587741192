.image-box{
height: 14rem;
    max-height: 14rem;  /* Set a fixed height */
    overflow-y: auto;   /* Enable vertical scroll */
    display: flex;
    flex-wrap: wrap;     /* Ensure images wrap properly */
    gap: 10px;          /* Add spacing between images */
    padding: 10px;
}


.logs-box{
    height: auto;
        max-height: auto;  /* Set a fixed height */
        overflow-y: auto;   /* Enable vertical scroll */
        display: flex;
        flex-wrap: wrap;     /* Ensure images wrap properly */
        gap: 10px;          /* Add spacing between images */
        padding: 10px;
    }

.ant-steps-item-title{
    color: whitesmoke !important;
}

.ant-steps-item-icon{
    background-color: #fff !important;
}


.label-heading1{
    color: #fff;
    font-size: 0.9rem;
}

.status-card{
    background: rgb(31, 41, 53) !important;
    border: 0px
}
.card-size {
    padding: 3%;
    margin-left: 2%;
    margin-top: 2%;
}
.card-size2 {

    margin-left: 2%;
    margin-top: 2%;
}
.card-size1 {
    padding: 1%;
      margin-left: 1.5%;
      margin-top: 1%;
      margin-right: 1%;

}


.ant-steps-item-process .ant-steps-item-icon{
    background: rgb(202 190 55) !important;
    border: rgb(202 190 55) !important;
}


.ant-steps-item-finish .ant-steps-item-icon{
    background: green !important;
    border: green !important;
    span{
        color: whitesmoke !important;
    }
}

.ant-steps-item-finish .ant-steps-item-title::after{
    background: green !important;

}





.ant-select-arrow{
color: whitesmoke !important;
}

.card-margin {
    margin: 0 1rem 0 1rem;
}






.ant-empty-description {
    color: #d9d9d9  !important;
}
.ant-table-placeholder{
    background: rgba(0, 0, 0, 0.15) !important;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder{
    background: rgba(0, 0, 0, 0.15) !important
}

.ant-select-selector{
    background: transparent !important;
    border-color: #d9d9d9 !important;
}







///////////////////////////////////////////////////////////////////
///
///
///
///
///
///
///
/// pf






.image-box{
    height: 14rem;
        max-height: 14rem;  /* Set a fixed height */
        overflow-y: auto;   /* Enable vertical scroll */
        display: flex;
        flex-wrap: wrap;     /* Ensure images wrap properly */
        gap: 10px;          /* Add spacing between images */
        padding: 10px;
        overflow-x: "hidden",
    }

   .download .ant-steps-item-title{
        color: black !important;
    }

    .ant-steps-item-icon{
        background-color: #fff !important;
    }


    .label-heading-download{
        color: black;
        font-size: large;
        font-weight: 600;

    }

    .status-card-download{
        background: #dee2e6 !important;
        border: 0px
    }
    .card-size-download {
        padding: 3%;
        margin-left: 1.5%;
        margin-top: 2%;
        margin-right: 1%;
        background: #dee2e6 !important;
    }
    .card-size1 {
        padding: 1%;
          margin-left: 1.5%;
          margin-top: 1%;
          margin-right: 1%;

    }


    .ant-steps-item-process .ant-steps-item-icon{
        background: rgb(202 190 55) !important;
        border: rgb(202 190 55) !important;
    }


    .ant-steps-item-finish .ant-steps-item-icon{
        background: green !important;
        border: green !important;
        span{
            color: whitesmoke !important;
        }
    }

    .ant-steps-item-finish .ant-steps-item-title::after{
        background: green !important;

    }





    .ant-select-arrow{
    color: whitesmoke !important;
    }

    .card-margin {
        margin: 0 1rem 0 1rem;
    }






    .ant-empty-description {
        color: #d9d9d9  !important;
    }
    .ant-table-placeholder{
        background: rgba(0, 0, 0, 0.15) !important;
    }

    .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder{
        background: rgba(0, 0, 0, 0.15) !important
    }

    .ant-select-selector{
        background: transparent !important;
        border-color: #d9d9d9 !important;
    }



.inner-cards{
   padding: 3% 5% 25px 5% !important;

}

.save-button:hover{
background:  rgb(29 84 36) !important;
}
.cancel-button:hover{
    background: rgb(131 41 30) !important;
}
.li-colors{
    color: whitesmoke;
    margin-top: 5%
}



.li-headings{
    color: whitesmoke;
    font-weight: bold;
}