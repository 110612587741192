* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

.body-padding {
  padding: 0px !important;
  background-color: rgb(21 33 45);;
}

.content-Setting {
  margin-left: 50px;
  margin-right: 50px;
}

.home-section1 {
  margin-left: auto;
  background: rgba(21,33,45,255) !important;
  position: relative;
  /* left: 260px; */

  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding-top: 1rem;
}

.sidebar.close~.home-section1 {
  left: auto;
  width: calc(100% - 78px);
}

.home-section1 .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}

.home-section1 .home-content .bx-menu,
.home-section1 .home-content .text {
  color: #11101d;
  font-size: 35px;
}

.home-section1 .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}

.home-section1 .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

.loginhere-link {
  font-weight: 700;
  color: rgb(5, 162, 179);
  text-decoration: none;
}
.form-title{
  color: whitesmoke;
}


@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}


.image-height {
  max-height: 50px;
}

.heading-content {
  display: flex;
      background-color: rgb(21 33 45);
      align-items: center;
      border-radius: 5px;
      margin-bottom: 1rem;
}
.heading-content1 {
  display: flex;
      background-color: none;
      align-items: center;
      border-radius: 5px;
      margin-bottom: 1rem;
}

.heading-content h5 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1.0rem;
  padding: 0.3rem 0 0.3rem 0.8rem;

}
.heading-content1 h5 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1.0rem;
  padding: 0.3rem 0 0.3rem 0.8rem;

}


table th{
  background-color: rgba(20, 33, 45, 255) !important;
  color: #fff !important;
}

.error-image{
  width: 100% !important;
}

.home-section {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  background-color: rgba(31,41,53,255); /* Optional: Set a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}
.bg_viewpopup {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.ant-picker-clear{
  font-size: 19px;
}
.ant-select .ant-select-clear{

  top: 42% !important;
  right: 12px  !important;
}
.anticon-close-circle{
  font-size: 19px;
}
.ant-table-tbody{
  background: rgba(31,41,53,255);
}
.ant-table-cell{
  color: white;
}

.custom-row-hover:hover {
  background-color: black;
}


.selected-row {
  background-color: black;
}
.selected-row:hover td {
  background-color:black !important;
}

.form-input {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 12px 12px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: whitesmoke;
  background:  #15212d !important;
}

.form-input::-webkit-input-placeholder {
  color: #999;
}

.form-input::-moz-placeholder {
  color: #999;
}

.form-input:-ms-input-placeholder {
  color: #999;
}

.form-input:-moz-placeholder {
  color: #999;
}

.form-input::-webkit-input-placeholder {
  font-weight: 500;
}

.form-input::-moz-placeholder {
  font-weight: 500;
}

.form-input:-ms-input-placeholder {
  font-weight: 500;
}

.form-input:-moz-placeholder {
  font-weight: 500;
}

.form-input:focus {
  border: 1px solid transparent;
  -webkit-border-image-source: -webkit-linear-gradient(to right, #9face6, #74ebd5);
  -moz-border-image-source: -moz-linear-gradient(to right, #9face6, #74ebd5);
  -o-border-image-source: -o-linear-gradient(to right, #9face6, #74ebd5);
  border-image-source: linear-gradient(to right, #9face6, #74ebd5);
  -webkit-border-image-slice: 1;
  border-image-slice: 1;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.form-input:focus::-webkit-input-placeholder {
  color: #222;
}

.form-input:focus::-moz-placeholder {
  color: #222;
}

.form-input:focus:-ms-input-placeholder {
  color: #222;
}

.form-input:focus:-moz-placeholder {
  color: #222;
}




.filter{
  padding: 2% !important;
  margin-bottom: 2% !important;
}



  .filter-button{
    display: flex !important;
    justify-content: flex-end !important;
    gap: 10px !important;
    margin-top:9% !important
  }

.btn-style{
  padding: 9px 20px 9px 20px;
}

.ant-select-selection-item{
  color: gray;
}

.logs.ant-steps-item-content>.ant-steps-item-description{
  color: whitesmoke !important;
}
.logs.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot{
  background: whitesmoke;
}

.logs.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description{
  color: whitesmoke !important;
}
.logs.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description{
  color: whitesmoke !important;
}
.logs.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after{
  background-color: green;
}

.logs.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after{
  background-color: green;
}

.ant-pagination .ant-pagination-item-active{
background-color: #575a5d !important;
}
.ant-select-selection-placeholder{color: #575a5d !important}


.container-size {
  margin: 9% auto;
  width: 30rem;
}
.fa-circle-info:before, .fa-info-circle:before {
  content: "";
  position: absolute;
  right: 21px;
  top: 27px;
}
.form-group {
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;

}

.btn-save:hover{
  background: rgb(5 162 179 / 60%) !important;
}
.btn-cancel:hover{
  background: rgb(244 67 54 / 68%) !important;
}